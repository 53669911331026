<script setup lang="ts">
import { TRENDING_SEARCHES_IDS } from '@integration-layer/data/xGenIds'

const { ts } = useI18n()
const { currentVisenzeConfig } = useAppConfig()
const { vSearchActive, uid } = useVisenzeImageSearch()

const { onLoaded, status } = useScript({
  src:
    'https://search.visenze.com/v2/widget-init?app_key=' +
    currentVisenzeConfig.vsearch_key +
    '&placement_id=' +
    currentVisenzeConfig.vsearch_id +
    '&container=.vs-image-search-camera&uid=' +
    uid.value,
  defer: true,
})

onLoaded(() => {
  vSearchActive.value = true
})

const openImageSearch = () => {
  if (status.value === 'awaitingLoad') return false

  const visenzeWidget = (window as any)?.[
    `visenzeWidget${currentVisenzeConfig.vsearch_id}`
  ]

  if (visenzeWidget) {
    visenzeWidget.openWidget()
  }
}

const { cookiesRecentSearches } = useRecentSearches()

const {
  searchValue,
  selectedBrands,
  brandOptions,
  results,
  filters,
  isLoadingResults,
  modalId,
  isDialogOpen,
  resetQuery,
  closeDialog,
  setBrandResultPage,
  smartLoadMore,
} = useSearch()

const {
  isFiltersBoxOpened,
  toggleFiltersBox,
  getActiveFilters,
  removeFilter,
  formatFilterLabel,
  selectedFilters,
} = useSearchFilters()

const {
  dispatchInternalSearchBrand,
  dispatchInternalSearchImage,
  dispatchInternalFilter,
} = useGAInternalSearchEvents()

const handleImageSearch = () => {
  openImageSearch()
  dispatchInternalSearchImage(searchValue.value ?? '')
}

watch(
  () => selectedBrands.value,
  (newValue, oldValue) => {
    if (newValue.length > oldValue.length) {
      const latestSelectedBrand = newValue[newValue.length - 1]

      dispatchInternalSearchBrand(searchValue.value, latestSelectedBrand ?? '')
    }
  }
)

const previousFilters = ref({ ...selectedFilters.value })
watch(
  () => selectedFilters.value,
  newFilters => {
    Object.entries(newFilters).forEach(([filterKey, filterValues]) => {
      const oldValues = previousFilters.value[filterKey] || []

      const addedValues = filterValues.filter(
        value => !oldValues.includes(value)
      )

      if (addedValues.length > 0) {
        const searchQuery = searchValue.value || ''
        const lastAddedFilter = addedValues[addedValues.length - 1]

        dispatchInternalFilter(searchQuery, filterKey, lastAddedFilter)
      }
    })
    previousFilters.value = JSON.parse(JSON.stringify(newFilters))
  },
  { deep: true }
)
const resultSectionTitle = computed(() => {
  if (searchValue.value) {
    return results.value.length === 0
      ? ts('search.results.noResults', { query: searchValue.value })
      : ts('search.results.query', { query: searchValue.value })
  }

  return ''
})

const closeDialogAndResetQuery = () => {
  closeDialog()
  setTimeout(() => {
    resetQuery()
  }, 500)
}
</script>
<template>
  <OrganismsPopover
    :id="modalId"
    has-responsive-transition
    is-full-height-mobile
    hide-close-button
    overlay-level
    is-scroll-locked
  >
    <template #body>
      <div class="grid-standard min-h-screen">
        <div
          class="col-span-12 flex flex-col md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3"
        >
          <div class="py-md gap-md flex items-center justify-between">
            <span class="text-medium-5">{{ ts('search.title') }}</span>
            <AtomsCta
              anatomy="link"
              @click-handler="closeDialogAndResetQuery()"
            >
              <template #label>
                {{ ts('close') }}
              </template>
            </AtomsCta>
          </div>
          <div>
            <div class="mb-4">
              <InputsSearch
                v-model="searchValue"
                name="search"
                variant="box"
                :placeholder="ts('search.input.placeholder')"
                :auto-focus="true"
                :show-visual-finder-icon="vSearchActive"
                @open-visual-finder="handleImageSearch"
              />
            </div>
            <MoleculesBrandSelector
              v-model="selectedBrands"
              :title="ts('search.brandSelector.title')"
              :options="brandOptions"
            />
            <SearchRecentSearches
              v-if="!searchValue && cookiesRecentSearches?.length"
              @click-on-search-term="searchValue = $event"
            />
          </div>
          <div class="flex-1 py-16">
            <div v-if="!searchValue && isDialogOpen">
              <XGenRecommendations
                v-for="(brand, index) in TRENDING_SEARCHES_IDS"
                :key="brand.predictionId"
                :prediction="brand.predictionId"
                :initial-cl-fetch="4"
              >
                <template #default="{ products, loadMore }">
                  <div
                    v-if="products.length"
                    :id="`XSE-${brand.predictionId}`"
                    class="XGen_SmartElement"
                  >
                    <span v-if="index === 0" class="text-book-6 block pb-4">
                      {{ ts('search.trendingSearches') }}
                    </span>
                    <SearchBrandResult
                      :id="brand.id"
                      :key="brand.name"
                      class="mb-xxl"
                      :name="brand.name"
                      :products="products"
                      @load-more="loadMore()"
                    />
                  </div>
                </template>
              </XGenRecommendations>
            </div>
            <SearchResults
              v-if="searchValue"
              :title="resultSectionTitle"
              :is-loading="isLoadingResults == true"
              :results="results"
              :search-value="searchValue"
            >
              <template #filters>
                <div
                  v-if="getActiveFilters"
                  class="mb-4 flex flex-wrap items-center gap-2"
                >
                  <div
                    v-for="activeFilter in getActiveFilters"
                    :key="activeFilter"
                    class="bg-primitives-off-white flex items-end px-4 py-2"
                  >
                    <button
                      :aria-labels="
                        $ts('aria-labels.removeFilter', {
                          filter: activeFilter,
                        })
                      "
                      @click="removeFilter(activeFilter)"
                    >
                      <DefaultIconsClose
                        width="16"
                        height="16"
                        aria-hidden="true"
                      />
                    </button>
                    <span class="text-book-7 ml-1">
                      {{ formatFilterLabel(activeFilter) }}
                    </span>
                  </div>
                </div>

                <SearchFiltersPanel
                  v-if="isFiltersBoxOpened"
                  :filters="filters"
                  @apply-filters="toggleFiltersBox()"
                />
              </template>
              <template #result="item">
                <SearchBrandResult
                  v-bind="item"
                  :search-result="searchValue"
                  xse-query
                  @set-pagination="setBrandResultPage"
                  @load-more="smartLoadMore(item.id)"
                />
              </template>
            </SearchResults>
          </div>
        </div>
      </div>
    </template>
  </OrganismsPopover>
</template>
