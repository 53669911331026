import type { AlgoliaProductType } from '../modules/05.productServices/runtime/components/WrapperProductTile.props'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

type BrandOption = {
  label: string
  value: string
  disabled: boolean
}

type SearchResultItem = {
  id: string
  name: string
  products: AlgoliaProductType[]
  page: number
  total: number
  isVisible: boolean
  isLoading?: boolean
}

export const useSearch = () => {
  const { saveRecentSearch } = useRecentSearches()
  const { search } = useXgen()
  const {
    skuPrices,
    resetAll: resetClPrices,
    fetchSkuPrices,
  } = useProductTileClPrices()
  const {
    removeAllFilters,
    activeFiltersCounter,
    closeFiltersBox,
    mergeFacets,
    filters,
  } = useSearchFilters()
  const { ts } = useI18n()
  const { currentWebsite } = useWebsite()

  const modalId = SIDEBAR_IDS.globalSearchModal

  const { isDialogOpen, openDialog, closeDialog } = useDialog(modalId)

  // TODO: (tracked #1263) searchable brands from Ampliance
  const brands = [
    {
      label: 'Giorgio Armani',
      value: 'giorgio-armani',
    },
    {
      label: 'Emporio Armani',
      value: 'emporio-armani',
    },
    {
      label: 'Ea7',
      value: 'ea7',
    },
    {
      label: 'Armani Exchange',
      value: 'armani-exchange',
    },
    {
      label: 'Armani Casa',
      value: 'armani-casa',
    },
  ]

  const brandOptions = ref<BrandOption[]>([
    { label: ts('search.brandSelector.all'), value: 'all', disabled: false },
    ...brands.map(el => ({ ...el, disabled: false })),
  ])
  const selectedBrands = ref<Array<string>>(['all'])

  const searchValue = ref('')
  const isLoadingResults = ref<boolean>(false)
  const results = ref<Array<SearchResultItem>>([])
  const selectedFilters = useState<{ [key: string]: string[] }>(
    'selected-filters'
  )

  watch(isDialogOpen, () => {
    const externalBrand = brands.find(
      brand => brand.value === currentWebsite.value
    )
    selectedBrands.value = [
      currentWebsite.value === 'global' || !externalBrand
        ? 'all'
        : currentWebsite.value,
    ]

    resetClPrices()
  })

  const fetchRecommendations = async (
    searchableBrands: BrandOption[],
    appliedFilters?: { [key: string]: string[] }
  ) => {
    return await Promise.all(
      searchableBrands.map(async brand => {
        const response = await search({
          q: searchValue.value,
          brand: brand.label,
          color: appliedFilters?.Color,
          microCat: appliedFilters?.microCat,
          macroCat: appliedFilters?.macroCat,
          gender: appliedFilters?.Gender,
          age: appliedFilters?.Age,
          size: appliedFilters?.size,
        })

        //2.1 Fetch prices from CL
        if (response.products && response.products.length) {
          const skuList = response.products
            .map((product: AlgoliaProductType) => product.size[0].SKU)
            .slice(0, 4)

          await fetchSkuPrices(skuList)

          const productsWithClPrice = response.products.map(
            (product: AlgoliaProductType) => {
              if (
                product.size[0].SKU &&
                skuPrices.value?.[product.size[0].SKU]
              ) {
                return {
                  ...product,
                  clPrices: skuPrices.value?.[product.size[0].SKU],
                }
              }
              return product
            }
          )

          return {
            ...response,
            products: productsWithClPrice,
          }
        }

        return response
      })
    )
  }

  /**
   * @watcher SelectedBrands
   * @description Add or remove the `all` value based on the brands selected by the user.
   * This ensures that when a specific brand is selected, the `all` option is deselected and vice versa.
   */
  watch(
    () => selectedBrands.value,
    async (_newValue, _oldValue) => {
      if (
        (_newValue.includes('all') && !_oldValue.includes('all')) ||
        _newValue.length === 0
      )
        nextTick(() => {
          selectedBrands.value = ['all']
        })
      else if (_oldValue.includes('all') && _newValue.length > 1) {
        nextTick(() => {
          selectedBrands.value = _newValue.filter(el => el !== 'all')
        })
      }

      isLoadingResults.value = true

      /**
       * If 'all' is selected, return and wait for the `SelectedBrands` watcher to clean up the selected brands.
       */
      if (
        selectedBrands.value.includes('all') &&
        selectedBrands.value.length > 1
      ) {
        return
      }

      /**
       * Update the results
       */
      try {
        // 1. Remove the `all` value from the searchableBrands array to exclude it from the search
        const searchableBrands = brandOptions.value.filter(el =>
          selectedBrands.value.includes('all')
            ? el.value !== 'all'
            : selectedBrands.value.includes(el.value)
        )

        // 2. Perform a search for each brand, including those that are not selected, and wait for all results
        const searchableBrandsResults = await fetchRecommendations(
          searchableBrands,
          selectedFilters.value
        )

        results.value = []

        filters.value = mergeFacets(searchableBrandsResults)

        searchableBrands.forEach(async (brand, index) => {
          // 3. Update the validity of each brand based on the search results
          const currentBrandOption = brandOptions.value.find(
            el => el.value === brand.value && el.value !== 'all'
          )
          if (currentBrandOption) {
            if (searchableBrandsResults[index].total === 0) {
              currentBrandOption.disabled = true
            } else {
              currentBrandOption.disabled = false
            }
          }

          // 4. Add the results to the results array
          if (
            searchableBrandsResults[index].total &&
            searchableBrandsResults[index].products.length > 0
          ) {
            results.value.push({
              id: brand.value,
              name: brand.label,
              products: searchableBrandsResults[index].products,
              page: searchableBrandsResults[index].page,
              total: searchableBrandsResults[index].total,
              isVisible:
                selectedBrands.value.includes('all') ||
                selectedBrands.value.includes(brand.value),
            })
          }
        })
      } finally {
        isLoadingResults.value = false
      }
    }
  )

  /**
   * @watcher SearchValue && SelectedBrands
   * @description When either `searchValue` or `selectedBrands` changes,
   * this logic ensures that all xGen components update their results
   * and deactivate any invalid brands.
   */
  watch(
    [() => searchValue.value],
    async ([searchValue], [oldSearchValue]) => {
      isLoadingResults.value = true

      /**
       * If 'all' is selected, return and wait for the `SelectedBrands` watcher to clean up the selected brands.
       */
      if (
        selectedBrands.value.includes('all') &&
        selectedBrands.value.length > 1
      ) {
        return
      }

      /**
       * Reset if the searched value is nullish
       */
      if (!searchValue) {
        results.value = []
        isLoadingResults.value = false
        brandOptions.value = brandOptions.value.map(brand => ({
          ...brand,
          disabled: false,
        }))
        return
      }

      /**
       * Reset all filters if the searched value changes
       */
      if (searchValue !== oldSearchValue) {
        removeAllFilters()
        closeFiltersBox()
      }

      /**
       * Update the results
       */
      try {
        // 1. Remove the `all` value from the searchableBrands array to exclude it from the search
        const searchableBrands = brandOptions.value.filter(el =>
          selectedBrands.value.includes('all')
            ? el.value !== 'all'
            : selectedBrands.value.includes(el.value)
        )

        // 2. Perform a search for each brand, including those that are not selected, and wait for all results
        const searchableBrandsResults =
          await fetchRecommendations(searchableBrands)

        saveRecentSearch(searchValue)

        results.value = []

        filters.value = mergeFacets(searchableBrandsResults)

        searchableBrands.forEach(async (brand, index) => {
          // 3. Update the validity of each brand based on the search results
          const currentBrandOption = brandOptions.value.find(
            el => el.value === brand.value && el.value !== 'all'
          )
          if (currentBrandOption) {
            if (searchableBrandsResults[index].total === 0) {
              currentBrandOption.disabled = true
            } else {
              currentBrandOption.disabled = false
            }
          }

          // 4. Add the results to the results array
          if (
            searchableBrandsResults[index].total &&
            searchableBrandsResults[index].products.length > 0
          ) {
            results.value.push({
              id: brand.value,
              name: brand.label,
              products: searchableBrandsResults[index].products,
              page: searchableBrandsResults[index].page,
              total: searchableBrandsResults[index].total,
              isVisible:
                selectedBrands.value.includes('all') ||
                selectedBrands.value.includes(brand.value),
            })
          }
        })
      } finally {
        isLoadingResults.value = false
      }
    },
    { deep: true }
  )

  watch(
    [() => ({ ...selectedFilters.value })],
    async ([appliedFilters]) => {
      isLoadingResults.value = true

      /**
       * Update the results
       */
      try {
        // 1. Remove the `all` value from the searchableBrands array to exclude it from the search
        const searchableBrands = brandOptions.value.filter(el =>
          selectedBrands.value.includes('all')
            ? el.value !== 'all'
            : selectedBrands.value.includes(el.value)
        )

        // 2. Perform a search for each brand, including those that are not selected, and wait for all results
        const searchableBrandsResults = await fetchRecommendations(
          searchableBrands,
          appliedFilters
        )

        results.value = []

        filters.value = mergeFacets(searchableBrandsResults)

        searchableBrands.forEach(async (brand, index) => {
          // 3. Update the validity of each brand based on the search results
          const currentBrandOption = brandOptions.value.find(
            el => el.value === brand.value && el.value !== 'all'
          )
          if (currentBrandOption) {
            if (searchableBrandsResults[index].total === 0) {
              currentBrandOption.disabled = true
            } else {
              currentBrandOption.disabled = false
            }
          }

          // 4. Add the results to the results array
          if (
            searchableBrandsResults[index].total &&
            searchableBrandsResults[index].products.length > 0
          ) {
            results.value.push({
              id: brand.value,
              name: brand.label,
              products: searchableBrandsResults[index].products,
              page: searchableBrandsResults[index].page,
              total: searchableBrandsResults[index].total,
              isVisible:
                selectedBrands.value.includes('all') ||
                selectedBrands.value.includes(brand.value),
            })
          }
        })
      } finally {
        isLoadingResults.value = false
      }
    },
    { deep: 1 }
  )

  /**
   * @method setBrandResultPage
   * @description update result for a specific brand passing the pagination
   */
  const setBrandResultPage = async (brand: string, page: number) => {
    const currentResult = results.value.find(
      (item: SearchResultItem) => item.id === brand
    )

    if (currentResult) {
      currentResult.isLoading = true

      try {
        const response = await search({
          q: searchValue.value,
          brand: currentResult.name,
          page,
        })

        // Fetch prices from CL
        const skuList = response.products.map(
          (product: AlgoliaProductType) => product.size[0].SKU
        )

        await fetchSkuPrices(skuList)

        const productsWithClPrice = response.products.map(
          (product: AlgoliaProductType) => {
            if (product.size[0].SKU && skuPrices.value?.[product.size[0].SKU]) {
              return {
                ...product,
                clPrices: skuPrices.value?.[product.size[0].SKU],
              }
            }
            return product
          }
        )

        if (response.products?.length > 0) {
          currentResult.products.push(...productsWithClPrice)
          currentResult.page = response.page
        }

        currentResult.isLoading = false
      } catch (error) {
        currentResult.isLoading = false
        console.error(error)
      }
    }
  }

  const resetQuery = () => (searchValue.value = '')

  const smartLoadMore = async (brand: string) => {
    const currentResult = results.value.find(item => item.id === brand)

    if (
      currentResult &&
      currentResult.products?.length &&
      currentResult.products.length > 4
    ) {
      currentResult.isLoading = true

      try {
        // Fetch prices from CL
        const skuList = currentResult.products
          .map(product => product.size[0].SKU)
          .filter(isNonNullable)

        await fetchSkuPrices(skuList)

        const productsWithClPrice = currentResult.products.map(product => {
          if (product.size[0].SKU && skuPrices.value?.[product.size[0].SKU]) {
            return {
              ...product,
              clPrices: skuPrices.value?.[product.size[0].SKU],
            }
          }
          return product
        })

        currentResult.products = productsWithClPrice
      } catch (error) {
        currentResult.isLoading = false
        console.error(error)
      } finally {
        isLoadingResults.value = false
      }
    }
  }

  return {
    modalId,
    searchValue,
    selectedBrands,
    brandOptions,
    results,
    filters,
    isLoadingResults,
    isDialogOpen,
    openDialog,
    closeDialog,
    setBrandResultPage,
    resetQuery,
    smartLoadMore,
  }
}
