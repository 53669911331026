<script setup lang="ts" generic="T">
import type { SearchResultsProps } from './SearchResults.props.ts'
import {
  SEARCH_DEPLOYMENT_ID,
  TRENDING_SEARCHES_NO_RESULTS_IDS,
} from '@integration-layer/data/xGenIds'

defineProps<SearchResultsProps>()
const { toggleFiltersBox, isFiltersBoxOpened, activeFiltersCounter } =
  useSearchFilters()
</script>

<template>
  <div class="flex min-h-full flex-col">
    <div
      v-if="isLoading && activeFiltersCounter === 0"
      class="flex grow items-center justify-center pb-16"
    >
      <AtomsLoaderIcon class="h-40 w-40" />
    </div>
    <div v-else>
      <div class="mb-6 flex">
        <span v-if="title" class="text-book-6 grow">
          {{ title }}
        </span>
        <div v-if="results?.length">
          <button
            v-if="!isFiltersBoxOpened"
            class="text-book-6 flex shrink-0 cursor-pointer items-center justify-start gap-2"
            :aria-labels="$ts('aria-labels.openFilters')"
            @click="toggleFiltersBox()"
          >
            {{ $ts('search.filters.label') }}
            {{ activeFiltersCounter ? `(${activeFiltersCounter})` : '' }}
            <DefaultIconsFilters width="20" height="20" aria-hidden="true" />
          </button>
          <button
            v-if="isFiltersBoxOpened"
            class="text-book-6 flex shrink-0 cursor-pointer items-center justify-start gap-2"
            :aria-labels="$ts('aria-labels.closeFilters')"
            @click="toggleFiltersBox()"
          >
            {{ $ts('search.filters.close') }}
            <DefaultIconsClose width="20" height="20" aria-hidden="true" />
          </button>
        </div>
      </div>

      <div v-if="results?.length > 0">
        <slot name="filters" />
        <div
          v-if="isLoading"
          class="flex grow items-center justify-center pb-16"
        >
          <AtomsLoaderIcon class="h-40 w-40" />
        </div>
        <ul
          v-else
          :xse-deployment-id="SEARCH_DEPLOYMENT_ID"
          class="XGen_SmartElement"
          aria-live="polite"
        >
          <li
            v-for="item in results"
            :key="item.id"
            :class="[
              '[&:not(:last-child)]:mb-xxl',
              { hidden: !item.isVisible },
            ]"
          >
            <slot name="result" v-bind="item" />
          </li>
        </ul>
      </div>
      <div v-if="searchValue && results.length === 0 && !isLoading">
        <XGenRecommendations
          v-for="(brand, index) in TRENDING_SEARCHES_NO_RESULTS_IDS"
          :key="brand.predictionId"
          :prediction="brand.predictionId"
          :initial-cl-fetch="4"
        >
          <template #default="{ products, loadMore }">
            <div
              v-if="products.length"
              :id="`XSE-${brand.predictionId}`"
              class="XGen_SmartElement"
            >
              <span v-if="index === 0" class="text-book-6 block pb-4">
                {{ $ts('search.trendingSearches') }}
              </span>
              <SearchBrandResult
                :id="brand.id"
                :key="brand.name"
                class="mb-xxl"
                :name="brand.name"
                :products="products"
                @load-more="loadMore()"
              />
            </div>
          </template>
        </XGenRecommendations>
      </div>
    </div>
  </div>
</template>
