<script setup lang="ts">
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const { quickBuyProps: props, quickBuyEvents: events } =
  useUIQuickBuyPopUpSlide()

const { isDialogOpen: isContactUsSideSlideOpen } = useDialog(
  SIDEBAR_IDS.cartContactUsSideSlide
)
</script>
<template>
  <div>
    <UIQuickBuyPopUpSlide
      v-if="props?.product"
      v-bind="props"
      :id="SIDEBAR_IDS.uiQuickBuyPopupSlide"
      :key="props.product.skuCode"
      @save-changes="events?.saveChanges"
    />
    <NewsletterSidebar />
    <SearchDialog @wheel.stop="''" @scroll.stop="''" />
    <CartContactUsSideSlide />
    <BookAnAppointmentSidebar
      v-if="!isContactUsSideSlideOpen"
      need-brand-selection
    />
  </div>
</template>
