<script setup lang="ts">
import type { MoleculesBrandSelector } from './MoleculesBrandSelector.props.ts'
defineProps<MoleculesBrandSelector>()

const model = defineModel()
</script>
<template>
  <div>
    <div v-if="title" class="text-book-6 pb-4 pt-2 md:pt-4">
      {{ title }}
    </div>
    <ul class="flex flex-wrap gap-2">
      <li
        v-for="option in options"
        :key="option.value"
        class="flex min-w-[calc(33%_-_16px)] flex-1 md:min-w-0"
      >
        <label
          :for="option.value"
          :class="[
            'focus-within:ring-primitives-blue w-full cursor-pointer focus-within:ring-1',
            { 'pointer-events-none': option.disabled },
          ]"
        >
          <input
            :id="option.value"
            v-model="model"
            type="checkbox"
            name="brand"
            class="peer absolute -z-50 opacity-0"
            :value="option.value"
            :disabled="option.disabled"
          />
          <div
            class="bg-primitives-off-white peer-checked:!border-primitives-black peer-disabled:!border-primitives-grey-50 flex min-h-full flex-col items-center justify-center gap-2 border border-solid border-transparent p-2 peer-disabled:opacity-20"
          >
            <component
              :is="menuLogoMap[option.value as MenuLogoType]"
              class="hidden h-8 w-8 md:block"
              aria-hidden="true"
            />
            <div
              class="text-book-7 text-primitives-black text-center uppercase"
            >
              {{ option.label }}
            </div>
          </div>
        </label>
      </li>
    </ul>
  </div>
</template>
