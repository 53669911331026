type Facets = Record<string, Record<string, number>>

export const useSearchFilters = () => {
  const selectedFilters = useState<{ [key: string]: string[] }>(
    'selected-filters',
    () => ({
      Color: [],
      Gender: [],
      macroCat: [],
      microCat: [],
      Age: [],
      size: [],
    })
  )

  const filters = ref<Facets>()

  const filtersOrder = [
    'Gender',
    'Age',
    'macroCat',
    'microCat',
    'Color',
    'size',
  ]

  const isFiltersBoxOpened = useState('isFiltersBoxOpened', () => false)

  const toggleFiltersBox = () => {
    isFiltersBoxOpened.value = !isFiltersBoxOpened.value
  }

  const closeFiltersBox = () => {
    isFiltersBoxOpened.value = false
  }

  const activeFiltersCounter = computed(() => {
    return Object.values(selectedFilters.value).reduce((count, value) => {
      if (Array.isArray(value)) {
        return count + value.length
      }
      return count
    }, 0)
  })

  const formatFilterLabel = (label: string) => {
    if (label.includes(' > ')) return label.split(' > ')[1]
    return label
  }

  const getActiveFilters = computed<string[]>(() => {
    let activeFilters: string[] = []
    for (const key in selectedFilters.value) {
      if (selectedFilters.value[key].length > 0) {
        activeFilters = activeFilters.concat(selectedFilters.value[key])
      }
    }
    return activeFilters
  })

  const removeFilter = (valueToRemove: string) => {
    for (const key in selectedFilters.value) {
      if (selectedFilters.value[key].includes(valueToRemove)) {
        selectedFilters.value[key] = selectedFilters.value[key].filter(
          value => value !== valueToRemove
        )
      }
    }
    return selectedFilters.value
  }

  const removeAllFilters = () => {
    for (const key in selectedFilters.value) {
      selectedFilters.value[key] = []
    }
    return selectedFilters.value
  }

  const mergeFacets = (data: { facets: Facets }[]): Facets => {
    const result: Facets = {}

    data.forEach(item => {
      if (!item.facets) {
        return
      }

      for (const [key, value] of Object.entries(item.facets)) {
        if (!result[key]) {
          result[key] = {}
        }

        for (const [subKey, subValue] of Object.entries(value)) {
          if (result[key][subKey]) {
            result[key][subKey] += subValue
          } else {
            result[key][subKey] = subValue
          }
        }
      }
    })

    return reorderKeys(result, filtersOrder)
  }

  return {
    selectedFilters,
    isFiltersBoxOpened,
    toggleFiltersBox,
    closeFiltersBox,
    activeFiltersCounter,
    getActiveFilters,
    removeFilter,
    removeAllFilters,
    formatFilterLabel,
    filters,
    mergeFacets,
  }
}
